<template>
  <div class="service-form-part">
    <ServiceRadioField :choices="services" v-on="$listeners" />
  </div>
</template>

<script>
import { Selectable } from 'chimera/all/components/models/Selectable'
import {
  sunscreensAwningsNL,
  sunscreensBlindsNL,
  sunscreensScreensNL,
  sunscreensSunshadesNL,
} from 'chimera/sunscreens/service'
import ServiceRadioField from 'chimera/all/components/form/fields/service/ServiceRadioField.vue'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      services: [
        new Selectable(
          'Zonneschermen',
          'Zonneschermen',
          sunscreensSunshadesNL.id,
        ),
        new Selectable('Markiezen', 'Markiezen', sunscreensAwningsNL.id),
        new Selectable('Rolluiken', 'Rolluiken', sunscreensBlindsNL.id),
        new Selectable('Screens', 'Screens', sunscreensScreensNL.id),
      ],
    }
  },
}
</script>
